import {MenuButtonFilter} from "./MenusButton";
import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { useGralContext } from '../Utils/Context';
import { ComboBox, Select1, Select2, Select3 } from './Selects'
import _ from 'lodash'

const AcordeonFilter = ({usedFilters , handleFiltersChange}) => {

    const { expanded, handleChange } = useGralContext();
    
    const [filters , setFilters] = React.useState({});

    const handleTypeChange = (e) => {
        const value = Number(e.target.value); 
    
        if (!isNaN(value) && value !== 0) {
            setFilters({
                "type_id": value
            });
        }else{
            setFilters({});
        }
    };

    const handleGenreChange = (e) => {
        const value = Number(e.target.value); 
    
        if (!isNaN(value) && value !== 0) {
            setFilters({
                ...filters,
                "genre_id": value
            })
        }else{
            const { genre_id , group_id , sound_id, ...rest } = filters;
            setFilters(rest);
        }
    }

    const handleGroupChange = (e) => {
        const value = Number(e.target.value); 
    
        if (!isNaN(value) && value !== 0) {
            setFilters({
                ...filters,
                "group_id": value
            })
        }else{
            const { genre_id , ...rest } = filters;
            setFilters(rest);
        }
    }

    React.useEffect(() => {
        if (!_.isEqual(filters, usedFilters)) {
          handleFiltersChange(filters);
        }
      }, [filters, usedFilters, handleFiltersChange]);

    return (
        <div>
            <Accordion className={`sounds__acordeon ${expanded === 'panel1' ? 'active-hover' : ''}`} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <MenuButtonFilter title='Type' />
                </AccordionSummary>
                <AccordionDetails className="d-flex flex-row justify-content-center">
                    <Select1  handleTypeChange={handleTypeChange}/>
                </AccordionDetails>
            </Accordion>
            <Accordion className={`sounds__acordeon ${expanded === 'panel2' ? 'active-hover' : ''}`} expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                >
                    <MenuButtonFilter title='Genre'  />
                </AccordionSummary>
                <AccordionDetails>
                    <AccordionDetails className="d-flex flex-row justify-content-center">
                        <Select2 filters={filters} handleGenreChange={handleGenreChange} />
                    </AccordionDetails>
                </AccordionDetails>
            </Accordion>
            <Accordion className={`sounds__acordeon ${expanded === 'panel3' ? 'active-hover' : ''}`} expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary
                    aria-controls="panel3bh-content"
                    id="panel3bh-header"
                >
                    <MenuButtonFilter title='Groups' />
                </AccordionSummary>
                <AccordionDetails className="d-flex flex-row justify-content-center">
                    <Select3 filters={filters} handleGroupChange={handleGroupChange}/>
                </AccordionDetails>
            </Accordion>
            <Accordion className={`sounds__acordeon ${expanded === 'panel4' ? 'active-hover' : ''}`} expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <AccordionSummary
                    aria-controls="panel4bh-content"
                    id="panel4bh-header"
                >
                    <MenuButtonFilter title='Sounds' />
                </AccordionSummary>
                <AccordionDetails className="d-flex flex-row justify-content-center">
                    <ComboBox />
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

export default AcordeonFilter;