import AllSounds from "./AllSounds";
import { useGralContext } from '../Utils/Context';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import AcordeonFilter from "./Acordeon";
import PlanetaryCanvas from "./Canvas/Planet";
import { AnimatedTitle } from "./Animations/Lines";
import { Link } from "react-router-dom";
import axiosClient from "../api/api";
import { useEffect, useState } from "react";


// Animacion filtros

const Base = () => {

    const { show, handleClose, toggleShow, filterNames , setSamplesData , currentSample , setCurrentSample} = useGralContext();

    const [filters , setFilters] = useState({})
    const [usedFilters, setUsedFilters] = useState({})

    const resetFilters = () => {
        setUsedFilters({})
        setFilters({})
    }

    const getSamples = async () => {
        const response = await axiosClient.post('filters/getSamples', filters);

        setSamplesData(response.data.sounds)

        setUsedFilters(response.data.usedFilters);

        if(!currentSample && response.data.sounds)
                setCurrentSample(response.data.sounds[0])
    }

    const handleFiltersChange = (filters) => {
        setFilters(filters)
    }

    useEffect(() => {
        getSamples()
    } , [filters])

    useEffect(() => {
        getSamples()
    } , [])

    return (
        <div>
            <div className="Base">
                {/* <motion.div className="containerr" ref={constraintsRef}>
                        <motion.div className="iteme"
                        drag
                        dragConstraints={constraintsRef}
                        />
                    </motion.div> */}
                <div className="sounds point">
                    {/* <Filters/> */}
                    <div className='point__absolute'>
                        <AnimatedTitle title='LOOPER' />
                        <PlanetaryCanvas />
                        <Link className="Links" to='/giftcards'><div className="cardbait carbaitanimation">BUY NOW YOUR DISCOUNT TICKETS</div></Link>
                        {/* <AnimatedTitleSm title='world of sounds'/>
                        <p className='titulos planetarytext1'>There is</p>
                        <p className='titulos planetarytext2'>a world of sounds</p>
                        <p className='titulos planetarytext3'>for you</p> */}
                    </div>

                    <Offcanvas backdrop={true} className='filterMenu__canvas' show={show} onHide={handleClose}>
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title className=" ms-4 mt-1 subtitulos">FILTER YOUR SAMPLES</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body className='filterMenu__canvas-body'>
                            <AcordeonFilter handleFiltersChange={handleFiltersChange} usedFilters={usedFilters}/>
                        </Offcanvas.Body>
                    </Offcanvas>
                    <div className="sounds__samples point">
                        <div className="sounds__buttons2">
                            <p className="playlist__title ps-0 fs-6 titulos pt-1 mb-1 ">{filterNames ? <p className='ps-0'><span className="playlist__title2  ps-0">Filters: </span> {filterNames}</p> : <p className='ps-0'><span className="playlist__title2 ps-0">These are </span>All the sounds</p>}</p>
                            <div className="sounds__buttons">
                                <Button variant='dark' onClick={toggleShow}>
                                    FILTERS
                                </Button>
                                <Button variant='dark' onClick={resetFilters}>RESET</Button> {/* Botón para restablecer los filtros */}
                                {/* Resto de tu interfaz */}
                            </div>
                        </div>
                        <AllSounds/>
                    </div>
                </div>
            </div>
        </div>



    );
}

export default Base;
