// Select
import { useGralContext } from '../Utils/Context';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import samplesData from '../Utils/samplesData';
import { useEffect, useState } from 'react'
import axiosClient from '../api/api';


function Select1({ handleTypeChange }) {
  const [filters, setFilters] = useState();

  const getTypes = async () => {
    const response = await axiosClient.get('filters/getTypes');
    setFilters(response.data)
  }

  useEffect(() => {
    getTypes()
  }, [])

  return (
    <div>
      <FormControl sx={{ m: 1, minWidth: 200 }} >
        <InputLabel id="demo-simple-select-autowidth-label">Type</InputLabel>
        <Select
          variant="outlined"
          labelId="demo-simple-select-autowidth-label"
          id="demo-simple-select-autowidth"
          onChange={(e) => handleTypeChange(e)}
          autoWidth
          label="Type"
        >
          <MenuItem className='Select' value="0">
            <em>All</em>
          </MenuItem>

          {filters && filters.map((filter, index) => (
            <MenuItem key={index} value={filter.id}>
              {filter.description}
            </MenuItem>
          ))}

        </Select>
      </FormControl>
    </div>
  );
}


function Select3({ filters, handleGroupChange }) {

  const [groups, setGroups] = useState([]);

  const getGroups = async (genreId) => {
    const response = await axiosClient.get('filters/getGroups', {
      params: { genre_id: genreId }
    });
    setGroups(response.data);
  }

  useEffect(() => {
    if (filters && filters.genre_id) {
      getGroups(filters.genre_id);
    }
  }, [filters]);

  return (
    <div>
      <FormControl sx={{ m: 1, minWidth: 200 }}>
        <InputLabel id="demo-simple-select-autowidth-label">Group</InputLabel>
        <Select
          labelId="demo-simple-select-autowidth-label"
          id="demo-simple-select-autowidth"
          onChange={(e) => handleGroupChange(e)}
          autoWidth
          label="Group"
          value={filters.group_id || ""}
        >
          <MenuItem value="">
            <em>All</em>
          </MenuItem>
          {groups && groups.map((group, index) => (
            <MenuItem key={index + group.description} value={group.id}>
              {group.description}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}


function Select2({ filters, handleGenreChange }) {

  const [genres, setGenres] = useState([]);

  const getGenres = async (typeId) => {
    const response = await axiosClient.get('filters/getGenres', {
      params: { type_id: typeId }
    });
    setGenres(response.data);
  }

  useEffect(() => {
    if (filters && filters.type_id) {
      getGenres(filters.type_id);
    }
  }, [filters]);


  return (
    <div>
      <FormControl sx={{ m: 1, minWidth: 200 }}>
        <InputLabel id="demo-simple-select-autowidth-label">Genre</InputLabel>
        <Select
          labelId="demo-simple-select-autowidth-label"
          id="demo-simple-select-autowidth"
          onChange={(e) => handleGenreChange(e)}
          autoWidth
          label="Group"
          value={filters.genre_id || ""}
        >
          <MenuItem value="">
            <em>All</em>
          </MenuItem>
          {genres && genres.map((genre, index) => (
            <MenuItem key={index + genre.description} value={genre.id}>
              {genre.description}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}


function ComboBox() {

  const { handleFilterChange } = useGralContext();
  const [selectedValue, setSelectedValue] = useState(null);

  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={samplesData}
      value={selectedValue}
      onChange={(event, newValue) => {
        setSelectedValue(newValue); // Almacenar el valor seleccionado
        handleFilterChange('name', newValue ? newValue.name : null); // Llamar a la función de manejo del filtro con el nuevo valor
      }}
      sx={{ width: 200, height: 80 }}
      renderInput={(params) => <TextField {...params} name="Sample" />}
    />
  );
}

export { Select1, Select2, Select3, ComboBox };