import axios from 'axios';

// Asegúrate de que la URL base esté correctamente configurada
const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL, 
    headers: {
        'Content-Type': 'application/json',
    },
});

axiosClient.interceptors.request.use(
    (config) => {
        return config;
    },
    (error) => {
        console.error('Request Error:', error); 
        return Promise.reject(error);
    }
);

axiosClient.interceptors.response.use(
    (response) => {
        // Devuelve la respuesta normalmente si no hay errores
        return response;
    },
    (error) => {
        // Manejo de errores
        if (error.response) {
            console.error('Response Error Data:', error.response.data);
            console.error('Response Error Status:', error.response.status);
            console.error('Response Error Headers:', error.response.headers);
        } else if (error.request) {
            console.error('Request Error (No Response):', error.request);
        } else {
            console.error('General Error:', error.message);
        }
        console.error('Error Config:', error.config);
        return Promise.reject(error); // Rechaza la promesa para que pueda ser capturada por `catch`
    }
);

export default axiosClient;
